import browserUpdate from 'browser-update/update.npm';

// See : https://github.com/browser-update/browser-update/wiki/Details-on-configuration
browserUpdate({
  required: {
    e: 95, // Edge
    i: 12, // IE
    f: 94, // Firefox
    s: 13.1, // Safari
    c: 96, // Chrome
  },
  insecure: true,
});
